import assert from 'assert';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const oauthBaseUrl = `https://${process.env.VUE_APP_COGNITO_DOMAIN!}/oauth2`

const client_id = process.env.VUE_APP_COGNITO_CLIENT_ID!;
const identity_provider = process.env.VUE_APP_COGNITO_IDENTITY_PROVIDER ?? '';
/* eslint-enable @typescript-eslint/no-non-null-assertion */



const queryString = new URLSearchParams(window.location.search);
const authCode = queryString.get('code');

/**
 * Initiate the process of logging in through AWS Cognito using OAuth Authrization Code flow with
 * PKCE.
 *
 * @param activity_code 
 */
export async function getAuthorizationCode(activity_code?: string): Promise<void> {
    // Since we are starting a new session, we discard the existing session token (if one exists).
    localStorage.removeItem("riddle_session_expiry");
    localStorage.removeItem("riddle_session");
    
    // Create a `state` value. When Cognito redirects the user back to this app after they've logged
    // in, it will include a `state` parameter and we will verify that it matches the one in session
    // storage. This is a security measure to prevent CSRF attacks.
    const pcke_state = randomString();
    sessionStorage.setItem('pkce_state', pcke_state);
    const state = encodeURIComponent(JSON.stringify({
        pcke_state,
        activity_code
    }));

    // Create a PKCE verifier and challenge. In the initial OAuth request (to obtain an
    // authorization code), we will send Cognito the challenge. In the second request (to exchange
    // the authorization code for tokens), we will prove that we are the same party that made the
    // initial request by sending the verifier, i.e. the un-hashed value of the challenge.
    const verifier = randomString();
    sessionStorage.setItem('pkce_verifier', verifier);

    const code_challenge = await sha256Encode(verifier);

    // Compose the query string and redirect the user to Cognito's authorization endpoint. This
    // will result in the user leaving the app but they will be redirected back, assuming
    // everything is configured properly.
    const query = (new URLSearchParams({
        response_type: "code",
        scope: "openid",
        code_challenge_method: "S256",
        state,
        client_id,
        code_challenge,
        identity_provider,
        redirect_uri: location.origin,
    })).toString();
    location.href = `${oauthBaseUrl}/authorize?${query}`;
}

export function authCodePresent(): boolean {
    return authCode !== null;
}

export async function exchangeCodeForTokens(): Promise<Record<string, string>> {
    // Clear the query string from the browser.
    window.history.pushState({}, document.title, window.location.pathname)

    assert(authCode, "Trying to exchange auth code for tokens, but no auth code present.")

    const returned_state = queryString.get('state');
    assert(returned_state, "No state returned in query string.");

    const {pcke_state, activity_code} = JSON.parse(decodeURIComponent(returned_state))
    // Check that the `state` values from session storage and from the URL both exist and match.
    const storedState = sessionStorage.getItem('pkce_state');
    assert(storedState, "Can't find stored state.");
    assert(storedState === pcke_state, "Incorrect state returned");

    const code_verifier = sessionStorage.getItem('pkce_verifier');
    assert(code_verifier, "Failed to retrieve PKCE verifier from session storate");

    // Compose the query string and make an asynchronous request to Cognito's token endpoint to
    // exchange the authorization code for a set of tokens.
    const query = (new URLSearchParams({
        client_id,
        code_verifier,
        code: authCode,
        grant_type: "authorization_code",
        redirect_uri: location.origin,
    })).toString();
    const url = `${oauthBaseUrl}/token?${query}`
    const tokens = await fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
        .then(response => response.json());

    // TODO: remove in production
    console.log("Raw tokens: ", tokens);
    const parsedToken = JSON.parse(atob(tokens["id_token"].split(".")[1]));
    console.log("Parsed ID token: ", parsedToken);
    console.log("Email from token: ", parsedToken["email"]);

    // Return the access token.
    assert('access_token' in tokens, "Failed to retrieve access token.\nResponse: " + JSON.stringify(tokens));
    return {
        access_token: tokens['access_token'],
        activity_code
    };
}

function randomString(length = 28): string {
    const decToHex = (dec: number) => dec.toString(16).padStart(2, "0");
    const items = new Uint8Array(length);
    crypto.getRandomValues(items);
    return Array.from(items, decToHex).join('');
}

/**
 * Generate a SHA-256 hash of the input text and return as a Base64URL-encoded string.
 * 
 * @param text 
 * @returns 
 */
async function sha256Encode(text: string): Promise<string> {
    // Hash the input text.
    const encodedData = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-256', encodedData);

    // Convert the hash from an ArrayBuffer to a Base64URL-encoded string.
    return btoa(String.fromCharCode(...new Uint8Array(hashBuffer)))
        .replace(/\+/g, '-')
        .replace(/\//, '_')
        .replace(/=+$/, '');
}