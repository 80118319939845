



































import Component from "vue-class-component";
import App from "../App.vue";
import ActivityStage from "./Utilities/ActivityStage.vue";

@Component({ components: { ActivityStage } })
export default class Waiting extends App {
    async mounted() {
      document.title = "Waiting Room | C2L Participant";
    }

    stage = {
        'waiting': true,
        'prompt': false,
        'response': false,
        'voting': false,
        'result': false,
    };
    get questions() {
        return this.$store.state.statesCount/5
    }
}

