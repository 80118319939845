import type { StoreType as Store } from '../store';
import * as backend from './models/outgoing-message';

export function getUserToken(): string {
    const tokenExpiry = localStorage.getItem("riddle_session_expiry");
    if (tokenExpiry && (new Date(tokenExpiry) > new Date())) {
        return localStorage.getItem("riddle_session") ?? '';
    } else {
        localStorage.removeItem("riddle_session_expiry");
        localStorage.removeItem("riddle_session");
        return '';
    }
}

export function resetUserToken(): void {
    localStorage.removeItem("riddle_session_expiry");
    localStorage.removeItem("riddle_session");
}

export function submitResponse(store: Store, response: string): void {
    const message: backend.SubmitResponse = {
        action: backend.OutgoingAction.SubmitResponse,
        stateProps: store.getters.stateProps,
        payload: { response }
    }
    store.getters.wsConnection.send(JSON.stringify(message));
}

export function submitBallot(store: Store, winnerId: string): void {
    const message: backend.SubmitVote = {
        stateProps: store.getters.stateProps,
        action: backend.OutgoingAction.SubmitVote,
        payload: {
            ballotUuid: store.getters.ballot.id,
            responses: Object.keys(store.getters.ballot.responses) as [string, string],
            winner: winnerId,
        }
    };
    store.getters.wsConnection.send(JSON.stringify(message));
}