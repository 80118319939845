









import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class ModalDialog extends Vue {
  get modalShow(): boolean {
    console.log('returning store.getter.modalShow', this.$store.getters.modalShow);
    return this.$store.getters.modalShow;
  }

  dismiss() {
    // if inactive activity alert was dismissed, refresh the page
    this.$bvModal.hide('bv-modal-dialog');
    location.replace('/');
  }
}
