import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import store from "./store";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Vue components
import Response from "./components/Response.vue";
import Results from "./components/Results.vue";
import Prompt from "./components/Prompt.vue";
import Voting from "./components/Voting.vue";
import Waiting from "./components/Waiting.vue";
import Welcome from "./components/Welcome.vue";

// import VueCountdownTimer from 'vuejs-countdown-timer';
// Vue.use(VueCountdownTimer);

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// State components must be Pascal case of state names (as defined in the backend)
Vue.component("Welcome", Welcome);
Vue.component("WaitingRoom", Waiting);
Vue.component("ShowPrompt", Prompt);
Vue.component("AcceptResponses", Response);
Vue.component("AcceptVotes", Voting);
Vue.component("ShowResults", Results);

new Vue({
  render: h => h(App),
  store
}).$mount("#vue-container");
