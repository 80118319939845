import { StateName } from './state';

export enum IncomingAction {
    GoToState = "go_to_state",
    NewBallot = "new_ballot",
    ResponseSuccess = "response_success",
    BallotSuccess = "ballot_success",
    RequireLogin = "require_login",
    SetToken = "set_token",
    InvalidToken = "invalid_token",
    ResumeSession = "resume_session",
    InactiveActivity = "inactive_activity",
    Pong = "pong"       // Send by API Gateway directly, not by Lambda.
}

export interface IncomingMessage {
    readonly action: IncomingMessage;
    readonly payload: any;
}

export interface GoToStatePayload {
    state: {
        activityUuid: string;
        question: number;
        name: StateName;
    };
    prompt?: string;
    question: any;
    results?: ParticipantResult;
    existingResponse?: string;
    currentStateIndex?: number;
    statesCount?: number;
}

export interface NewBallotPayload {
    readonly id: string;
    readonly responses: { [id: string]: string };
    readonly round: number;
}

export interface ParticipantBallotResult {
    opposingResponse: string;
    won: boolean;
    comment?: string;
}

export interface ParticipantResult {
    readonly ballots: ParticipantBallotResult[];
    readonly responseText: string;
}
