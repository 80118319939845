







































import Component from "vue-class-component";
import App from "../App.vue";
import ActivityStage from "./Utilities/ActivityStage.vue";

@Component({ components: { ActivityStage } })
export default class Prompt extends App {
    async mounted() {
        document.title = "Topic | C2L Participant";
    }
    stage = {
        'waiting': false,
        'prompt': true,
        'response': false,
        'voting': false,
        'result': false,
    };
    get prompt(): string {
        return this.$store.state.prompt;
    }
    get questions() {
        return this.$store.state.statesCount/5
    }
}

