import getOnMessageListener from "./incoming-message";
import { initiatePingPongLoop } from "./ping-pong";
import { Mutations } from "../store";
import type { StoreType as Store } from '../store';

const url = process.env.VUE_APP_WEBSOCKET_URL;

/**
 * Initiate the WebSocket connection.
 * 
 * @param store The Vuex store in which to keep the WebSocket connection.
 * @param payload Either the user token or the code of the activity to join.
 * @param rejoin True if rejoining using a user token, false otherwise (this is the default). 
 */
export default function wsConnect(store: Store, activityCode: string, rejoin: boolean, accessToken?: string): void {
  if (!url) throw Error("No WebSocket URL.")
  const connection = new WebSocket(url);
  store.commit(Mutations.SET_WS_CONNECTION, connection);
  connection.onopen = getOnOpenListener(connection, activityCode, rejoin, accessToken);
  connection.onmessage = getOnMessageListener(store);
  connection.onclose = () => getOnCloseListener(store);
}


/**
 * Generate a function that will be used as the onopen listener for the WebSocket. The function
 * returned by this function will be called as soon as the connection is established.
 *
 * @param ws The WebSocket object on which we will send messages.
 * @param payload Either the user token or the code of the activity to join.
 * @param rejoin True if rejoining using a user token, false otherwise. 
 */
function getOnOpenListener(ws: WebSocket, payload: string, rejoin: boolean, accessToken?: string): () => void {
  return () => {
    ws.send(JSON.stringify({
      initType: rejoin ? "userToken" : "activityCode",
      payload,
      accessToken
    }));
    initiatePingPongLoop(ws);
  };
}

function getOnCloseListener(store: Store): () => void {
  return () => {
    console.warn("WebSocket connection closed by host.");
    store.commit(Mutations.ALERT, { id: 'ws_conn', status: 'danger', icon: 'x-circle-fill', message: "WebSocket connection closed by host." });
  }
}