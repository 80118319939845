



































































import { required, minLength } from "vuelidate/lib/validators";
import { Validate } from "vuelidate-property-decorators";
import Component from "vue-class-component";
import { getUserToken } from "../websocket/outgoing-message";
import App from "../App.vue";
import assert from "assert";
import * as login from "../cognito-login";

@Component
export default class Welcome extends App {
  connecting = false;
  token = "";
  @Validate({ required, minLength: minLength(4) })
  code: string | null = null;
  access_token: string | null = null;

  async mounted() {

    document.title = "Welcome | C2L Participant";
    const codePresent = login.authCodePresent();
    console.log("Auth code present: ", codePresent);

    if (codePresent) {
      const {
        access_token,
        activity_code
      } = await login.exchangeCodeForTokens();
      if (activity_code) {
        this.join(activity_code, access_token);
      } else {
        this.access_token = access_token;
      }
    }

    // get the code & token (empty strings if not set)
    this.code = new URLSearchParams(window.location.search).get("activityCode");
    this.token = getUserToken();
    if (this.token && this.token.length > 0) {
      // if there's a user token, ask whether to rejoin
      this.$root.$emit("bv::show::modal", "rejoinModal", "#codeInput");
    } else if (this.code && this.code.length > 0) {
      // otherwise, if there's a code in the url, join automatically
      this.connecting = true;
      this.initiateConnection(this.code);
    }
  }

  async initLogin() {
    login.getAuthorizationCode();
  }

  rejoin() {
    this.connecting = true;
    this.initiateConnection(this.token, true);
  }
  join(code: string, accessToken?: string) {
    this.connecting = true;
    this.initiateConnection(code, false, accessToken);
    // TODO: on failure, display a message & enable button
  }

  onContinue() {
    this.$v.$touch();
    assert(this.code && !this.$v.$invalid, "Invalid code");
    this.join(this.code, this.access_token ?? undefined);
  }
}
