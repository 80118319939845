























import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Mutations, Alert } from "../../store";

@Component
export default class AlertTray extends Vue {
  dismissSeconds = 10;

  get alerts(): Array<Alert> {
    return this.$store.getters.alerts;
  }

  dismiss(index: number, alertId: string) {
    this.$store.commit(Mutations.DISMISS_ALERT, { index });

    // if inactive activity alert was dismissed, refresh the page
    if (alertId === 'inactive_activity') {
      location.reload();
    }
  }
}
