import { IncomingAction } from './models/incoming-message';

const PING = JSON.stringify({ action: "ping" });
// The number of times to attempt the ping-pong before giving up.
const MAX_ATTEMPTS = 3;
// How long to wait for a pong after sending a ping.
const PONG_TIMEOUT = 5000;
// The interval at which to send pings. Note that connections will timeout after 10min of inactivity
// so the interval should be shorter than that.
// Here we choose 9min 40s because above we have specified that each time we attempt to ping-pong,
// we may make up to 3 attempts, each of which waits 5 seconds to receive a pong which, in total
// may take up to 3*5=15 seconds. Adding an extra 5 second safety margin, we allow a total of 20s
// for the ping-pong operation. Subtracting 20s from 10min (i.e. the maximum amount of time allowed
// to pass between WS messages before the connection times out) gives use 9m 40s, or 580000ms.
const INTERVAL = 580000;

/**
 * This function should be called as soon as the WS connection is opened. It is responsible for
 * periodically sending a ping and processing a pong response.
 * 
 * @param ws 
 */
export function initiatePingPongLoop(ws: WebSocket): void {
    console.log("initiating ping pong loop");
    setInterval(initiatePingPong, INTERVAL, ws);
}

/**
 * This function sends a ping and waits for a pong. If no pong is received after PING_TIMEOUT ms,
 * another ping will be sent, up to MAX_ATTEMPT times.
 * 
 * @param ws 
 */
async function initiatePingPong(ws: WebSocket) {
    for (let i = 0; i < MAX_ATTEMPTS; i++) {
        // A function that will be added as a listener to the WS's "message" event. It is
        // declared in this scope so that it can be deregistered as a listener.
        let callback: (event: MessageEvent) => void = () => {/* do nothing */}
        
        // A promise which resolves to true after a pong is received.
        const pongPromise = new Promise(resolve => {
            ws.send(PING);

            // For each message, if its action is "pong" then resolve the promise to true.
            callback = (event: MessageEvent) => {
                const message = JSON.parse(event.data);
                if (message.action === IncomingAction.Pong)
                    resolve(true);
            }

            // Register the callback as a listener on the WS connection.
            ws.addEventListener("message", callback);
        });

        // A promise that returns false after PONG_TIMEOUT ms.
        const timeout = new Promise(resolve => setTimeout(() => resolve(false), PONG_TIMEOUT));

        // Race the two promises, and resolve to the value of whichever one resolves first.
        const pongReceived = await Promise.race([pongPromise, timeout]);

        // Deregister the callback.
        ws.removeEventListener("message", callback);

        // If a pong was received, return. Otherwise, continue with the for loop.
        if (pongReceived) return;
    }
    // This line is only reached if all ping-pong attempts have failed.
    // TODO handle failed ping-pong
    console.log("failed all ping-pong attempts")
}