import Vue from "vue";
import Vuex from "vuex";
import { NewBallotPayload, ParticipantResult } from './websocket/models/incoming-message';
import { pascalCase } from 'change-case';
import { StateProps } from './websocket/models/state';

Vue.use(Vuex);

type Optional<T> = T | undefined;

export type Alert = {
  id: string,
  message: string,
  status: string,
  icon: Optional<string>,
}

export enum SubmissionStatus {
  UNSUBMITTED = "unsubmitted",
  SUBMITTING = "submitting",
  ACCEPTED = "accepted"
}

export enum Mutations {
  SET_WS_CONNECTION = "setWsConnection",
  SET_BALLOT = "setBallot",
  SET_STATE = "setState",
  RESPONSE_SUCCESS = "responseSuccess",
  RESPONSE_SUBMITTED = "responseSubmitted",
  BALLOT_SUCCESS = "ballotSuccess",
  ALERT = "alert",
  DISMISS_ALERT = "dismissAlert",
  DISMISS_ALL = "dismissAll",
  RESET_STATE = "resetState",
  MODALSHOW = "modalShow"
}

const getDefaultState = () => {
  return {
    wsConnection: undefined as Optional<WebSocket>,
    stateProps: {
      activityUuid: undefined as Optional<string>,
      question: undefined as Optional<number>,
      name: undefined as Optional<string>
    },
    question: undefined as Optional<any>,
    prompt: undefined as Optional<string>,
    responseSubmissionStatus: SubmissionStatus.UNSUBMITTED,
    existingResponse: undefined as Optional<string>,
    ballot: {
      id: undefined as Optional<string>,
      responses: {} as { [id: string]: string },
      round: undefined as Optional<number>,
    },
    ballotSuccess: false,
    results: undefined as Optional<ParticipantResult>,
    alerts: [] as Array<Alert>,
    currentStateIndex: 0,
    statesCount: 0,
    modalShow: false
  }
}

const store = new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    [Mutations.SET_WS_CONNECTION](state, wsConnection: WebSocket) {
      state.wsConnection = wsConnection;
    },
    [Mutations.SET_BALLOT](state, ballot: NewBallotPayload) {
      // Unlike responseSubmissionStatus, we cannot reset when we change states because there can be multiple
      // ballots in a single voting state.
      state.ballotSuccess = false;
      state.ballot.id = ballot.id;
      state.ballot.responses = ballot.responses;
      state.ballot.round = ballot.round;
    },
    [Mutations.SET_STATE](state, input: {
      stateProps: StateProps,
      prompt?: string,
      results?: ParticipantResult,
      existingResponse?: string,
      currentStateIndex?: number,
      statesCount?: number,
    }) {
      Object.assign(state, input);
      state.responseSubmissionStatus = SubmissionStatus.UNSUBMITTED;
    },
    [Mutations.RESPONSE_SUCCESS](state) {
      state.responseSubmissionStatus = SubmissionStatus.ACCEPTED;
    },
    [Mutations.RESPONSE_SUBMITTED](state) {
      state.responseSubmissionStatus = SubmissionStatus.SUBMITTING;
    },
    [Mutations.BALLOT_SUCCESS](state) {
      state.ballotSuccess = true;
    },
    [Mutations.ALERT](state, input: Alert) {
      state.alerts.push(input);
    },
    [Mutations.DISMISS_ALERT](state, input: {index: number}) {
      state.alerts.splice(input.index, 1);
    },
    [Mutations.DISMISS_ALL](state) {
      state.alerts = [];
    },
    [Mutations.RESET_STATE](state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, getDefaultState())
    },
    [Mutations.MODALSHOW](state) {
      console.log('modal show mutation!');
      state.modalShow = true;
    },
  },
  getters: {
    stateProps: state => state.stateProps,
    stateName: state => state.stateProps.name ? pascalCase(state.stateProps.name) : 'welcome',
    ballot: state => state.ballot,
    wsConnection: state => state.wsConnection,
    prompt: state => state.prompt,
    results: state => state.results,
    ballotSuccess: state => state.ballotSuccess,
    responseSubmissionStatus: state => state.responseSubmissionStatus,
    existingResponse: state => state.existingResponse,
    alerts: state => state.alerts,
    currentStateIndex: state => state.currentStateIndex,
    statesCount: state => state.statesCount,
    modalShow: state=> state.modalShow,
  },
});

export default store;
export type StoreType = typeof store;
