








import Vue from "vue";
import Component from "vue-class-component";
import createConnection from "./websocket/connection";
import AlertTray from "./components/Utilities/AlertTray.vue";
import ModalDialog from "./components/Utilities/ModalDialog.vue";

@Component({ components: { AlertTray, ModalDialog } })
export default class App extends Vue {
  initiateConnection(payload: string, rejoin = false, accessToken?: string) {
    createConnection(this.$store, payload, rejoin, accessToken);
  }
}
