





























































































































import Component from "vue-class-component";
import App from "../App.vue";
import {
    ParticipantResult as Result,
    ParticipantBallotResult as Ballot
} from "../websocket/models/incoming-message";
import BallotResult from "./Utilities/BallotResult.vue";
import { Mutations } from "../store";
import { resetUserToken } from "../websocket/outgoing-message";
import ActivityStage from "./Utilities/ActivityStage.vue";


@Component({ components: { ActivityStage, BallotResult } })
export default class Results extends App {

    async mounted() {
        document.title = "Results | C2L Participant";
    }

    showBallots = false;
    showFinished = false;
    stage = {
        'waiting': false,
        'prompt': false,
        'response': false,
        'voting': false,
        'result': true,
    };

    get results(): Result {
        if (this.$store.getters.statesCount - this.$store.getters.currentStateIndex === 1) {
            this.showFinished = true;
        }
        return this.$store.getters.results;
    }

    get questions() {
        return this.$store.state.statesCount/5
    }

    get prompt(): string {
        return this.$store.getters.prompt;
    }

    get wins(): Ballot[] {
        return this.results.ballots.filter(ballot => ballot.won);
    }

    get losses(): Ballot[] {
        return this.results.ballots.filter(ballot => !ballot.won);
    }

    get score(): number {
        return this.wins.length / this.results.ballots.length;
    }

    closeActivity() {
// redirect to the home page
        window.location.assign(
            window.location.href.substr(0, window.location.href.indexOf("?"))
            );
// change stateName to "Welcome" and reset userToken
        this.$store.commit(Mutations.RESET_STATE);
        resetUserToken();
    }
}

