




























































































import { submitBallot } from "../websocket/outgoing-message";
import { Component, Watch } from "vue-property-decorator";
import App from "../App.vue";
import Instructions from "./Utilities/Instructions.vue";
import ActivityStage from "./Utilities/ActivityStage.vue";

@Component({ components: { ActivityStage, Instructions } })
export default class Voting extends App {

    errorMessage: string | null = null;

    async mounted() {
        document.title = "Voting | C2L Participant";
    }

    stage = {
        'waiting': false,
        'prompt': false,
        'response': false,
        'voting': true,
        'result': false,
    };
    selectedAnswer: string | null = null;
    submitted = false;
    arr: string[] = [];
    j = 1;

    get question() {
        return this.$store.state.question.params;
    }
    get round() {
        return this.$store.state.ballot.round;
    }
    get questions() {
        return this.$store.state.statesCount/5
    }
    get responses() {
        return this.$store.getters.ballot.responses;
    }
    get accepted() {
        return this.$store.getters.ballotSuccess;
    }
    setItem(key: string, value: string) {
        return window.sessionStorage.setItem(key, value);
    }
    getItem(key: string) {
        return JSON.parse(window.sessionStorage.getItem(key) || `${this.j}`);
    }
    @Watch("responses")
    resetBallot() {
// when the ballot options change, reset all input
        this.submitted = false;
        this.selectedAnswer = null;
    }

    isSelectedSr(id: string) {
        return id === this.selectedAnswer
        ? "This answer has been selected."
        : "This answer is not selected.";
    }

    onSubmit() {
        if (this.selectedAnswer) {
            submitBallot(this.$store, this.selectedAnswer);
            this.submitted = true;
            this.errorMessage = null; // Clear any previous error message
        } else {
            // Set the error message if no answer is selected
            this.errorMessage = "Please select an answer before submitting.";
        }
          // TODO on error message, notify user and enable button
    }
}

