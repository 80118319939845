






















































































import { submitResponse } from "../websocket/outgoing-message";
import { required, maxLength } from "vuelidate/lib/validators";
import { Validate } from "vuelidate-property-decorators";
import Component from "vue-class-component";
import App from "../App.vue";
import Instructions from "./Utilities/Instructions.vue";
import { SubmissionStatus, Mutations } from "../store";
import ActivityStage from "./Utilities/ActivityStage.vue";

@Component({ components: { ActivityStage, Instructions } })

export default class Response extends App {

    async mounted() {
        document.title = "Respond | C2L Participant";
    }

    show_help = false;

    error_message = 'The response field cannot be empty nor exceed 250 characters.';

    stage = {
        'waiting': false,
        'prompt': false,
        'response': true,
        'voting': false,
        'result': false,
    };

    get questions() {
        return this.$store.state.statesCount/5
    }
    get status() {
        return this.$store.getters.responseSubmissionStatus;
    }
    get unsubmitted() {
        return this.status === SubmissionStatus.UNSUBMITTED;
    }
    get submitting() {
        return this.status === SubmissionStatus.SUBMITTING;
    }
    get accepted() {
        return this.status === SubmissionStatus.ACCEPTED;
    }

    @Validate({ required, maxLength: maxLength(250) })
    response = this.$store.state.existingResponse ? this.$store.state.existingResponse : '';

    get prompt(): string {
        return this.$store.state.prompt;
    }

    get characterCounter(): string {
        var char = this.response.length;
        var limit = 250;

        return (limit - char) + " / " + limit + " characters remaining";
    }

    onSubmit() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
            submitResponse(this.$store, this.response);
            this.$store.commit(Mutations.RESPONSE_SUBMITTED);
        }
    }
    onHelp(value: boolean) {
        this.show_help = value;
    }
}

