





























import Component from "vue-class-component";
import App from "../../App.vue";

@Component({ props: { index: Number, ballot: Object, type: String } })
export default class BallotResult extends App {}
