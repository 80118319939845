import { Mutations } from '../store';
import { IncomingAction, GoToStatePayload, NewBallotPayload } from './models/incoming-message';
import { getAuthorizationCode } from '../cognito-login'
import type { StoreType as Store } from '../store';

/**
 * Generate a function that will be used as the onmessage listener for the Websocket. The returned
 * function will be called each time a WebSocket message is received.
 *
 * @param store The Vuex data store.
 */
export default function getOnMessageListener(store: Store): (event: MessageEvent) => void {
    return async (event: MessageEvent) => {
        const message = JSON.parse(event.data);
        // error messages have a different structure (no action or payload), so we check fort the
        // presence of the message attribute instead
        if (message.message) return errorMessage(store, message.message);
        // if the attribute is absent, we have a regular ('action') message
        switch (message.action as IncomingAction) {
            case IncomingAction.GoToState: return goToState(store, message.payload);
            case IncomingAction.NewBallot: return newBallot(store, message.payload);
            case IncomingAction.ResponseSuccess: return responseSuccess(store, message.payload);
            case IncomingAction.BallotSuccess: return ballotSuccess(store, message.payload);
            case IncomingAction.SetToken: return setToken(store, message.payload);
            case IncomingAction.ResumeSession: return resumeSession(store, message.payload);
            case IncomingAction.RequireLogin: return initiateLogin(message.payload);
            case IncomingAction.InvalidToken: return handleInvalidToken(store);
            case IncomingAction.InactiveActivity: return handleInactiveActivity(store);
        };
    }
}

function goToState(store: Store, payload: GoToStatePayload): void {
    console.log("go to state payload: ", payload);
    store.commit(Mutations.SET_STATE, {
        stateProps: payload.state,
        question: payload.question,
        prompt: payload.prompt,
        results: payload.results,
        existingResponse: payload.existingResponse,
        currentStateIndex: payload.currentStateIndex,
        statesCount: payload.statesCount
    });
    store.commit(Mutations.DISMISS_ALL);
}

function newBallot(store: Store, payload: NewBallotPayload): void {
    console.log("Showing new ballot. Payload: ", payload);
    store.commit(Mutations.SET_BALLOT, payload)
}

function setToken(store: Store, payload: any): void {
    console.log("Setting token. Payload: ", payload);

    const expiry = new Date();
    expiry.setHours(expiry.getHours() + 1);

    localStorage.setItem("riddle_session", payload.token);
    localStorage.setItem("riddle_session_expiry", expiry.toJSON());
}

function resumeSession(store: Store, payload: any): void {
    console.log("Resuming session. Payload: ", payload);
    // TODO nothing for now
}

function responseSuccess(store: Store, payload: any): void {
    console.log("Response success. Payload: ", payload);
    store.commit(Mutations.RESPONSE_SUCCESS);
    store.commit(Mutations.ALERT, {id: 'resp_submit',  status: 'success', icon: 'check-circle-fill', message: 'Submitted!' });
}

function ballotSuccess(store: Store, payload: any): void {
    console.log("Ballot success. Payload: ", payload);
    store.commit(Mutations.BALLOT_SUCCESS);

    // Find the index of the alert with id 'ballot_submit'
    const index = store.state.alerts.findIndex(alert => alert.id === 'ballot_submit');

    // If the alert exists, dismiss it
    if (index !== -1) {
        store.commit(Mutations.DISMISS_ALERT, { index });
    }

    // Get the round value from the Vuex store state
    const round = store.state.ballot?.round;

    if (round !== undefined) {
        // Add 1 to round as the round index starts at 0 i.e round 1 will display as 0 without intervention
        const incrementedRound = round + 1;

        // Add a delay before showing the new alert with correct round number
        setTimeout(() => {
            store.commit(Mutations.ALERT, { id: 'ballot_submit', status: 'success', icon: 'check-circle-fill', message: 'Voting For Round ' + incrementedRound + " Submitted!" });
        }, 1000);
    }
    else {
        // Add a delay before showing the new alert
        setTimeout(() => {
            store.commit(Mutations.ALERT, { id: 'ballot_submit', status: 'success', icon: 'check-circle-fill', message: 'Submitted!' });
        }, 1000);
    }

}

function errorMessage(store: Store, message: string): void {
    console.log(`Error message: ${message}`);
    if (message.includes("The activity has not yet started.")) {
        store.commit(Mutations.ALERT, { id: 'inactive_activity', status: 'danger', icon: 'x-circle-fill', message: message });
    } else{
        store.commit(Mutations.ALERT, { id: 'error_general', status: 'danger', icon: 'x-circle-fill', message: `Something went wrong: ${message}` });
    }
}

async function initiateLogin({activityCode}: {activityCode: string}) {
    console.log("executing initiateLogin()")
    await getAuthorizationCode(activityCode);
}

function handleInvalidToken(store: Store) {
    console.log("Invalid token.")

    localStorage.removeItem("riddle_session_expiry");
    localStorage.removeItem("riddle_session");
    store.commit(Mutations.ALERT, { id: 'invalid_token', status: 'danger', icon: 'x-circle-fill', message: `Invalid token.` });
}

function handleInactiveActivity(store: Store) {
    console.log('inactive activity incoming message has been received!');
    store.commit(Mutations.MODALSHOW);
}
