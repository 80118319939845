import { StateName, StateProps } from './state';

export enum OutgoingAction {
  SubmitResponse = "submit_response",
  SubmitVote = "submit_vote",
}

export interface OutgoingMessage {
  stateProps: StateProps;
  action: OutgoingAction;
  payload?: any;
}

export interface SubmitVote extends OutgoingMessage {
  stateProps: StateProps & { name: StateName.AcceptVotes }
  action: OutgoingAction.SubmitVote;
  payload: {
      ballotUuid: string;
      responses: [string, string];
      winner: string;
      comment?: string;
  };
}

export interface SubmitResponse extends OutgoingMessage {
  stateProps: StateProps & { name: StateName.AcceptResponses }
  action: OutgoingAction.SubmitResponse;
  payload: {
      response: string;
  }
}